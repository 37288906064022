import axios from 'axios';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { Audio, ColorRing } from 'react-loader-spinner';
import logo from "../../Img/Untitled .png";
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Login() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const { t } = useTranslation();
  const { url, lan } = useContext(UserContext);
  let navigat = useNavigate()
  function handleLogin(values) {
    setIsLoading(true);
    axios.post(`${url}/login?lang=${lan}`, values)
      .then(response => {
        setIsLoading(false);
        toast.success(response?.data?.message);
       
        
        localStorage.setItem("token", response?.data?.data?.token)
        navigat("/")
      })
      .catch(error => {
        setIsLoading(false);
        toast.error(error?.response?.data?.message);
        // console.log(error?.response?.data?.message)
        console.log(error);
        
        setError(error?.response?.data?.message);
      });
  }

  const validationSchema = yup.object({
    email: yup.string().email("Invalid email address").required("Email is required"),
    password: yup.string().min(2, "Password must be at least 6 characters").required("Password is required")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema,
    onSubmit: handleLogin
  });

  return (
    <div className='container'>
    <Helmet>
      <title> {t("login")} </title> 
    </Helmet>
      <div className='row'>
        <div className='col-lg-3 col-md-6 col-sm-12'></div>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <form onSubmit={formik.handleSubmit} className='boxshado'>
            {/* <div className='logoimglogin mx-auto mt-5 pb-4'>
          <img src={logo} className='w-100' alt="logo" />
        </div> */}
            <div className='  mt-5 mx-3'>
              {error && <div className='alert alert-danger p-3'>{error}</div>}

              <label htmlFor='email' className='mt-5 text-white'> {t("email")} :</label>
              <input
                name='email'
                type='email'
                id='email'
                className='form-control inputdesine mt-3'
                placeholder='Email'
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && formik.touched.email &&
                <div className="alert alert-danger">{formik.errors.email}</div>
              }

              <label htmlFor='password' className='mt-3 text-white'>{t("password")}:</label>
              <div className="input-group">
                <input
                  name='password'
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  id='password'
                  className='form-control inputdesine mt-3'
                  placeholder='Password'
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-outline-secondary showPassword mt-3"
                    onClick={() => setShowPassword(!showPassword)} // Toggle show/hide password
                  >
                    {/* Replace with custom icon */}
                    {showPassword ? <i class="fa-solid fa-eye-slash"></i> : <i class="fa-solid fa-eye"></i>}
                    {/* <i className={showPassword ? 'icon-eye-slash' : 'icon-eye'}></i> */}
                  </button>
                </div>
              </div>
              {formik.errors.password && formik.touched.password &&
                <div className="alert alert-danger">{formik.errors.password}</div>
              }

              <div className='d-flex justify-content-between pb-3'>
                <div className="d-flex mt-3">
                  {isLoading ?
                    <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> :
                    <button disabled={!(formik.isValid && formik.dirty)} className='btn bg-color' type="submit"> {t("login")} </button>
                  }
                </div>
                <div className='mt-3'>
                  <a href='/rejster' className='text-white'> {t("loginnweacount")}   </a>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className='col-lg-3 col-md-6 col-sm-12'></div>

      </div>

    </div>
  );
}
