import { createContext, useState, useEffect } from "react";

export let UserContext = createContext()

export default function UserContextprovider(props) {

    const [counter, setCounter] = useState(0);
    const [lan, setLan] = useState(() => localStorage.getItem('language') || 'en'); // التخزين المحلي لحفظ اللغة
    const url = "https://api.aestheticdental.org/api/v1";

    useEffect(() => {
        localStorage.setItem('language', lan); // تخزين اللغة في التخزين المحلي
    }, [lan]);

    function childrenCount(){
        setCounter(Math.random());
    }

    return (
        <UserContext.Provider value={{ counter, childrenCount, url, lan, setLan }}>
            {props.children}    
        </UserContext.Provider>
    );
}
