import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { UserContext } from '../Context/Context';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { BallTriangle } from 'react-loader-spinner';

export default function DoctorServices() {
    const { t } = useTranslation();
    const [Date, setDate] = useState([]);
    const [loading, setLoading] = useState(true);

    const { url, lan } = useContext(UserContext);
let {id} = useParams()

// console.log(id);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/services?lang=${lan}`);
                setDate(response?.data?.data || []);
            } catch (error) {
                console.error("Error fetching doctors data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [lan, url , Date]);

    // console.log(Date);
    

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    if (loading) return <BallTriangle
    height={100}
    width={100}
    radius={5}
    color="#0d2338"
    ariaLabel="ball-triangle-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    />;
// console.log(Date);

    return (
        <div className='container'>
            <div className='bgsection' id='Doctors'>
           
                <div className='container'>
                    <div className='row mt-4 '>
                        <Slider {...settings}>
                            {Date?.map((doctor, index) => (
                                <div key={index}>
                                    <div className='bg-color boxshado'>
                                        <Link to={`/DetalseServices/${doctor.id}`}>
                                            <div className='bg-color '>
                                                <div className='pt-3 mx-auto d-center flex-column  '>
                                                    <img className='img-sercel' src={doctor.image || 'defaultImage.png'} alt={`Dr. ${doctor.name}`} />
                                                    <p className='text-color'>{doctor.specialization}</p>
                                                    <h4 className='fw-bold mt-3 text-color '> {doctor?.name}</h4>
                                                </div>
                                                <div className='d-center m-0 p-0'>
                                                    <p className='text-color mx-1 fw-bold '> {doctor?.speciality} </p>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className='text-center text-color pb-3'>
                                            {/* <Link to={`/DetalseServices/${doctor.id}`} className="btn btn-color mb-3 d-center">
                                                Dr. details
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* <ImgDoctors /> */}
            </div>
        </div>
    );
}
