import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
import axios from 'axios';

export default function ImgDoctors() {

    const { t } = useTranslation();
    const [faqData, setFaqData] = useState([]); // Use array for multiple FAQs
    const [loading, setLoading] = useState(true);
  
    const { url, lan } = useContext(UserContext);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${url}/home?lang=${lan}`);
          setFaqData(response?.data?.data?.doctorGallery || []); // Ensure 's an array
        } catch (error) {
          console.error("Error fetching FAQ data", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [lan, url]);
console.log(faqData);

    
    return <>
        <div className='container mt-5'>
            <div className='row pb-5'>
            {
                faqData?.map((img)=><>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className='w-100' key={img?.id}>
                        <img src={img?.image} className='img-fluid w-100 h-card' />
                    </div>
                </div>
                </>)
            }
         
          
            </div>
        </div>
    </>
}
