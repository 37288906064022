import React from 'react'
import img from '../../Img/error-404-concept-illustration_114360-1811.avif'
export default function NotFound() {
  return (
    <div className='container'>
<div className='row'>
<div className='col-3'></div>
    <div className='col-6'>
      <div className='mx-auto d-center'>
        <img src={img} className='w-100' />
      </div>
    </div>
<div className='col-3'></div>
</div>


    </div>
  )
}
