import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserContext } from '../Context/Context';
import DoctorServices from './DoctorServices';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';

export default function DetalseServices() {
    const { t } = useTranslation();
    const { id } = useParams();
    const { url, lan } = useContext(UserContext);

    const [data, setData] = useState(null);  // Use null for initial state
    const [loading, setLoading] = useState(true);

    // Fetch data whenever `id` or `lan` changes
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true); // Set loading true before fetching
        try {
          const response = await axios.get(`${url}/services/${id}?lang=${lan}`);
          setData(response?.data?.data || null);
        } catch (error) {
          console.error("Error fetching services data", error);
        } finally {
          setLoading(false); // Stop loading after fetching
        }
      };
  
      fetchData();
    }, [id, lan, url]); // Add `id` as a dependency to re-fetch when it changes

    if (loading) {
        return <div>
                <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0d2338"
                ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
        </div>; // Add any loader or skeleton here
    }

    if (!data) {
        return <div>No data found.</div>;
    }

    return (
        <div className='container'>
            <div className='row mt-3 boxshado'>
                <div className='col-lg-6 col-md-12 mt-5'>
                    <div>
                        <div className='w-75 px-3'>
                            <div className='d-flex justify-content-between align-content-between'>
                                <p className='text-white'> {t("name")} </p>
                                <p className='text-color fw-bold mx-2'>  {data?.name} </p>
                            </div>
                            <div className='d-flex mt-2 justify-content-between align-content-between'>
                                <p className='text-white'> {t("price")} </p>
                                <p className='text-color fw-bold mx-2'>  {data?.price} </p>
                            </div>
                            <div className='d-flex mt-2 justify-content-between align-content-between'>
                                <p className='text-white'> {t("description")} </p>
                                <p className='text-color fw-bold mx-2'> {data?.description} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='p-5'>
                        <img src={data?.image} className='w-100' alt={data?.name} />
                    </div>
                </div>
            </div>
            <DoctorServices />
        </div>
    );
}
