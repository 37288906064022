import React, { useContext, useState } from 'react';
import logoimg from "../../Img/contactus.jpg";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { UserContext } from '../Context/Context';
import { useTranslation } from 'react-i18next';
import { ColorRing } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';

export default function ContactUs() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { url, lan } = useContext(UserContext); // Get the URL from the context
    const { t } = useTranslation();
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload on form submit
        setLoading(true); // Start loading

        const formData = {
            name,
            email,
            phone,
            subject,
            message
        };

        try {
            // Replace 'your-api-endpoint' with the actual API endpoint
            await axios.post(`${url}/contact-us?lang=${lan}`, formData);
            toast.success('Message sent successfully!');
            // Clear form
            setName('');
            setEmail('');
            setPhone('');
            setSubject('');
            setMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
            toast.error('Failed to send message. Please try again.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <>
            <div className='container mt-5'>
            <Helmet>
        <title>تواصل معانا</title>
      </Helmet>
                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='boxshado p-4'>
                            <h5 className='text-center fw-bold text-color'>{t("contactus")}</h5>
                            <form onSubmit={handleSubmit}>
                                <div className='form-group mt-2'>
                                    <label htmlFor='name' className='text-color fw-bold'> {t("name")}  :</label>
                                    <input
                                        type='text'
                                        id='name'
                                        className='form-control mt-2'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='form-group mt-2'>
                                    <label htmlFor='email' className='text-color fw-bold'>{t("email")} :</label>
                                    <input
                                        type='email'
                                        id='email'
                                        className='form-control mt-2'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='form-group mt-2'>
                                    <label htmlFor='phone' className='text-color fw-bold'>{t("phone")}:</label>
                                    <input
                                        type='tel'
                                        id='phone'
                                        className='form-control mt-2'
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='form-group mt-2'>
                                    <label htmlFor='subject' className='text-color fw-bold'>{t("subject")}:</label>
                                    <input
                                        type='text'
                                        id='subject'
                                        className='form-control mt-2'
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='form-group mt-2'>
                                    <label htmlFor='message' className='text-color fw-bold'> {t("message")}:</label>
                                    <textarea
                                        id='message'
                                        className='form-control mt-2'
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                    />
                                </div>
                                <button
                                    type='submit'
                                    className='btn btn-color mt-3'
                                    disabled={loading} // Disable button while loading
                                >
                                    {loading ? <ColorRing
                                        visible={true}
                                        height="80"
                                        width="80"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={['#0d2338']}
                                    /> : t("send")}
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 mt-5'>
                        <img src={logoimg} className='w-100 rounded rounded-lg mt-5' alt="Contact Us" />
                    </div>
                </div>
            </div>
        </>
    );
}
