import React, { useState, useEffect } from 'react';
import Loading from './Loading';
import { useTranslation } from 'react-i18next';
// import Loading from './Loading'; // تأكد من وجود مكون Loading

export default function Fixedbody() {
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        // محاكاة عملية تحميل
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // تغيير المدة حسب احتياجاتك

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className='container'>
            {loading && <Loading />}
            {!loading && (
                <>
                    <a href="https://wa.me/+971555096097?text=I'd%20like%20to%20make%20an%20appointment" className=" d-none d-lg-block" target="_blank" rel="noopener noreferrer">
                        <div className='fixedwhite d-center '>
                            <i className="fa-brands fa-whatsapp"></i>
                        </div>
                    </a>

                    <a href="tel:+971555096097" className=" d-none d-lg-block">
                        <div className='fixedwhit d-center'>
                            <i className="fa-solid fa-phone"></i>
                        </div>
                    </a>
                    <div className='d-block d-lg-none'>
                        <div className='divResponseve'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <a href="https://wa.me/+971555096097?text=I'd%20like%20to%20make%20an%20appointment" className='text-white d-center mt-2'>
                                            <div className='iconeresponsev d-center mt-4 mx-1 mx-auto'>
                                                <img src='https://cdn.pixabay.com/photo/2015/08/03/13/58/whatsapp-873316_1280.png' className='w-100' />
                                            </div>
                                           {/* <p className='mt-2'> WhatsApp Us</p> */}
                                            </a>
                                    </div>
                                    <div className='col-6'>
                                        <a href='mailto:Info@redseahospital.net' className='text-white d-flex flex-column align-items-center mt-2'>
                                            <div className='iconeresponsev d-flex align-items-center justify-content-center mt-4 mx-1 mx-auto'>
                                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                            </div>
                                            {/* {t("email")} */}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
