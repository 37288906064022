import React, { useContext } from "react";
import { FirebaseContext } from "../Firbase/ContextFirbase";
import Slider from '../Slider/Slider'
import Navbar from "../Navbar/Navbar";
import MinNavbar from "../Navbar/MinNavbar";
import About from "../About/About";
import Services from "../Services/Services";
import OurServices from "../Services/OurServices";
import CounterServices from "../Services/CounterServices";
import ImgServices from "../Services/ImgServices";
import Testmonials from "../Testmonials/Testmonials";
import OurDoctors from "../OurDoctors/OurDoctors";
import BookAppointment from "../BookAppointment/BookAppointment";
import Footer from "../Footer/Footer";
import Fixedbody from "../Fixed/Fixedbody";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Slidernav from "../Navbar/Slidernav";

export default function Home() {

  const { t } = useTranslation();



  return (
    <div className="container-fluid2">
      <Helmet>
    <title> {t("homepage")} </title>
  </Helmet>

      {/* <Navbar/> */}
     <Slidernav></Slidernav>
      <Slider />
      <About />
      <Services />
      <OurServices />
      <CounterServices />
      <ImgServices />
      {/* <Testmonials/> */}
      <OurDoctors />
      {/* <BookAppointment /> */}
      <Fixedbody />
    </div>
  );
}
