import axios from 'axios';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { Audio } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { UserContext } from '../Context/Context';
import toast from 'react-hot-toast';
import logo from "../../Img/Untitled .png"
import { useTranslation } from 'react-i18next';

export default function Register() {
    const { t } = useTranslation();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setdata] = useState([])
    let navigate = useNavigate(); // Programmatic navigation
    const { url, lan, setLan } = useContext(UserContext);
    async function register(values) {
        setIsLoading(true);
        try {
            let { data } = await axios.post(`${url}/register?lang=${lan}`, values);

            localStorage.setItem("token", data?.data?.token)
            setdata(data)
            setIsLoading(false);
            // Handle successful registration (e.g., navigate to login page)
            navigate('/');
        } catch (error) {
            setIsLoading(false);
            console.log(error?.response?.data?.message);
            
            toast.error(error?.response?.data?.message)
            setError(error?.response?.data?.message);
        }
    }


    let phoneRegex = /^[0-9]{11}$/;

    let validationSchema = yup.object({
        first_name: yup.string()
            .min(3, "يجب أن يكون الاسم على الأقل 3 أحرف")
            .max(10, "يجب ألا يتجاوز الاسم 10 أحرف")
            .required("الاسم مطلوب"),
        last_name: yup.string()
            .min(3, "يجب أن يكون الاسم على الأقل 3 أحرف")
            .max(10, "يجب ألا يتجاوز الاسم 10 أحرف")
            .required("الاسم مطلوب"),
        email: yup.string()
            .email("بريد إلكتروني غير صالح")
            .required("البريد الإلكتروني مطلوب"),
        phone: yup.string()
            .matches(phoneRegex, "رقم الهاتف غير صالح")
            .required("رقم الهاتف مطلوب"),
        password: yup.string()
            .matches(/^[A-Z][a-z0-9]{5,10}$/, "يجب أن تتكون كلمة المرور من 6 إلى 11 حرفًا، وتبدأ بحرف كبير، وتتبعها حروف صغيرة وأرقام")
            .required("كلمة المرور مطلوبة"),

    });

    let formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            password: "",
            country_id: "1"
        },
        validationSchema,
        onSubmit: register
    });
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-12'></div>
                    <div className='col-lg-6 col-md-12 boxshado'>
                        <div className=''>
                            <form onSubmit={formik.handleSubmit} className='mt-5 mx-2'>
                                {error && <div className='alert alert-danger p-3'>{error}</div>}
                                <label htmlFor='name'> {t("first_name")} :</label>
                                <input
                                    className='form-control mt-2 inputdesine'
                                    placeholder="first_name"
                                    name="first_name"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.first_name}
                                    id="name"
                                />
                                {formik.errors.first_name && formik.touched.first_name && (
                                    <div className="alert alert-danger">{formik.errors.first_name}</div>
                                )}

                                <label htmlFor='last_name' className='mt-2'> {t("last_name")}:</label>
                                <input
                                    className='form-control mt-2 inputdesine'
                                    placeholder="Last Name"
                                    name="last_name"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.last_name}
                                    id="last_name"
                                />
                                {formik.errors.last_name && formik.touched.last_name && (
                                    <div className="alert alert-danger">{formik.errors.last_name}</div>
                                )}

                                <label htmlFor='email' className='mt-2'> {t("email")}:</label>
                                <input
                                    className='form-control mt-2 inputdesine'
                                    type="email"
                                    name="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    id="email"
                                />
                                {formik.errors.email && formik.touched.email && (
                                    <div className="alert alert-danger">{formik.errors.email}</div>
                                )}

                                <label htmlFor='phone' className='mt-2'> {t("phone")}:</label>
                                <input
                                    className='form-control mt-2 inputdesine'
                                    type="tel"
                                    name="phone"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                    id="phone"
                                />
                                {formik.errors.phone && formik.touched.phone && (
                                    <div className="alert alert-danger">{formik.errors.phone}</div>
                                )}

                                <label htmlFor='password' className='mt-2'> {t("password")}:</label>
                                <input
                                    className='form-control mt-2 inputdesine'
                                    type="password"
                                    name="password"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    id="password"
                                />
                                {formik.errors.password && formik.touched.password && (
                                    <div className="alert alert-danger">{formik.errors.password}</div>
                                )}



                                <div className="mt-3 mb-5">
                                    {isLoading ?
                                        <Audio height="80" width="80" radius="9" color="green" ariaLabel="loading" /> :
                                        <button disabled={!(formik.isValid && formik.dirty)} className='btn bg-color' type="submit">Register</button>
                                    }
                                </div>
                            </form>
                        </div>



                    </div>
                    <div className='col-lg-3 col-md-12'></div>
                </div>
            </div>

            <div className='w-50 mx-auto mt-5'>





            </div>
        </>
    );
}
