import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
import { Helmet } from 'react-helmet';

export default function About() {
    const { t } = useTranslation();
    const languag = localStorage.getItem("language");
    const [Date, setDate] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { url, lan } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/home?lang=${lan}`);
                setDate(response?.data?.data?.content || []);
            } catch (error) {
                console.error("Error fetching slider data", error);
                setError("Failed to load data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [lan, url]);

    const getYouTubeVideoId = (url) => {
        const regExp = /^.*((youtu\.be\/)|(.+\/v\/)|(.+\/embed\/)|(.+\/watch\?v=))((\w|-){11})/;
        const match = url.match(regExp);
        return match && match[6] ? match[6] : null; // Return video ID or null if invalid
    };

    if (loading) {
        return <div>Loading...</div>; // Show loading state
    }

    if (error) {
        return <div>{error}</div>; // Show error message
    }

    return (
        <>
            <div id='about'>
                <div className='container pt-5'>
                    <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                            <div className={languag === "ar" ? "text-end" : "text-start"}>
                                <h2 className='text-color mt-1'>{Date[0]?.title}</h2>
                                <p className='text-color mt-4'>{Date[0]?.description}</p>


                                {/* 
                                <a href={`https://wa.me/971555096097`} className="btn btn-color" target="_blank" rel="noopener noreferrer">
                                       {t("appointmentmake")}
                               </a> */}


                                <a href={`https://wa.me/971555096097`} className="btn btn-color text-color" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-whatsapp"></i> {t("appointmentmake")}
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <div className='w-100'>
                                <div className='wp-block-tornado-about-section alignfull about-us' id='AboutUs'>
                                    <div>
                                        <div className='about-video'>
                                            <iframe
                                                className='w-100'
                                                width="560" // You can adjust the width as needed
                                                height="315" // You can adjust the height as needed
                                                src={`https://www.youtube.com/embed/${getYouTubeVideoId(Date[0]?.file)}`}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
