import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import logo from "../../../src/Img/Untitled .png"
import { useLocation } from 'react-router-dom';
import { UserContext } from '../Context/Context';
import { Helmet } from 'react-helmet';

export default function Slidernav() {
    const { t } = useTranslation();
    const { lan, setLan } = useContext(UserContext);

    const [language, setLanguage] = useState(() => localStorage.getItem('language') || lan || 'en');
    const [mymode, setMymode] = useState(() => localStorage.getItem('theme') || 'light');
    const [isScrolled, setIsScrolled] = useState(false);
    const changeLanguage = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
        setLanguage(selectedLanguage);
        setLan(selectedLanguage); // تحديث اللغة في الـ context
        localStorage.setItem('language', selectedLanguage);
    };
    let { pathname } = useLocation()
    // console.log(pathname);
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    // Function to handle scrolling behavior


    return (
        <>



            <div className=' d-none d-lg-block'>
                <div className={`bg-color ${isScrolled ? 'navbar-scrolled' : ''}`}>
                    <nav className="navbar navbar-expand-lg nav-h-100">
                        <div className="container">
                            <div className="navbar-brand text-color">
                                <select value={language} onChange={changeLanguage} className='btnNav px-4 mx-2 '>
                                    <option value="en">EN</option>
                                    <option value="ar">AR</option>
                                    <option value="de">DE</option>
                                </select>
                                {/* <>
                                    {localStorage.getItem("token") ?
                                        <div class="dropdown mt-2">
                                            <button class="btn px-4 bg-color dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                {t("profile")}
                                            </button>
                                            <ul class="dropdown-menu bg-color " aria-labelledby="dropdownMenuButton1">
                                                <li><a class="dropdown-item" href="/profile">       {t("profile")}</a></li>
                                                <li><a class="dropdown-item" href="#"> {t("logout")} </a></li>
                                            </ul>
                                        </div> :
                                        <a href='/login' className='text-decoration-none' rel='noopener noreferrer'>
                                            <div className='d-block btnNav mt-1'>
                                                <div className='text-color mx-3 pt-1'> {t("communication")}  </div>
                                            </div>
                                        </a>}
                                </> */}

                            </div>
                            <button
                                className="navbar-toggler text-dark"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <i className="fa-solid fa-bars fa-2x"></i>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav m-auto ">
                                    <li className="nav-item mx-1">
                                        <a className={pathname == "/" ? "nav-link fw-bold infonav text-color" : " nav-link fw-bold text-color"} href="/">{t("home")}</a>
                                    </li>
                                    <li className="nav-item mx-1">
                                        <a className={pathname == "/about" ? "nav-link fw-bold infonav text-color" : "  nav-link fw-bold text-color"} href="/about">{t("about")}</a>
                                    </li>
                                    <li className="nav-item mx-1">
                                        <a className={pathname == "/AllServices" ? "nav-link fw-bold infonav text-color" : " nav-link fw-bold text-color"} href="/AllServices">  {t("services")}   </a>
                                    </li>
                                    <li className="nav-item mx-1">
                                        <a className="nav-link fw-bold text-color" href="#Doctors">  {t("ourteam")}  </a>
                                    </li>

                                    <li className="nav-item mx-1">
                                        <a className={pathname == "/contactus" ? "nav-link fw-bold infonav text-color" : " nav-link fw-bold text-color"} href="/contactus">    {t("contactus")} </a>
                                    </li>


                                    <li className="nav-item mx-1">
                                        <a className={pathname == "/FAQ" ? "nav-link fw-bold infonav text-color" : " nav-link fw-bold text-color"} href="/FAQ">  {t("faq")} </a>
                                    </li>

                                    <li className="nav-item mx-1">
                                        <a className={pathname == "/Reviews" ? "nav-link fw-bold infonav text-color" : " nav-link fw-bold text-color"} href="Reviews">   {t("reviews")} </a>
                                    </li>


                                </ul>
                                <form className="d-flex img-nav ">
                                    <a href='/'>

                                        <img src={logo} className='w-130 ' />
                                    </a>
                                </form>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}
