import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';

export default function FAQ() {
  const { t } = useTranslation();
  const [faqData, setFaqData] = useState([]); // Use array for multiple FAQs
  const [loading, setLoading] = useState(true);

  const { url, lan } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/app-question?lang=${lan}`);
        setFaqData(response?.data?.data || []); // Ensure it's an array
      } catch (error) {
        console.error("Error fetching FAQ data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lan, url]);

  if (loading) return <div>
          <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0d2338"
                ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
  </div>; // Handle loading state

  return (
    <div className='container mt-5'>
      <Helmet>
        <title> {t("faq")} </title>
      </Helmet>

      <h2 className='text-center mb-4 text-color'>{t("faq")}</h2>

      <div className="accordion bg-color mt-5" id="accordionExample">
        {faqData?.map((faq, index) => (
          <div className="accordion-item bg-color" key={faq.id || index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button className="accordion-button bg-color text-color" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === 0 ? "true" : "false"} aria-controls={`collapse${index}`}>
                {faq.question}
              </button>
            </h2>
            <div id={`collapse${index}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
              <div className="accordion-body text-color">
                {faq.answer}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
