import React, { useState, useContext } from 'react';
import ShowReviews from './ShowReviews';
import axios from 'axios';
import { UserContext } from '../Context/Context';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function Reviews() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false); // Loading state for button

  const { url, lan } = useContext(UserContext); // Get the URL from the context

  const handleStarClick = (star) => {
    setRating(star);
  };

  const Star = ({ star }) => (
    <span
      onClick={() => handleStarClick(star)}
      className={`star ${star <= rating ? 'filled' : ''}`}
    >
      ★
    </span>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const reviewData = {
      name,
      email,
      comment,
      rate: rating,
    };

    try {
      const response = await axios.post(`${url}/reviews?lang=${lan}`, reviewData);

      // Display message from backend in toast
      toast.success(response?.data?.message || 'Review submitted successfully!');

      // Reset form fields after successful submission
      setName('');
      setEmail('');
      setComment('');
      setRating(0);
    } catch (error) {
      console.error('Error submitting review:', error);

      // Display backend error message or a fallback message
      toast.error(error?.response?.data?.message || 'Failed to submit review');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
       <Helmet>
        <title>اضافة تعليق</title>
      </Helmet>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-lg-6 col-md-12'>
            <div className='addreviews'>
              <div className='pt-4'>
                <h5 className='text-center fw-bold'>  {t("addreviews")} </h5>
                <form className='mt-4 mx-3' onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor='Name' className='mt-3 fw-bold'> {t("name")} :</label>
                    <input
                      name='Name'
                      id='Name'
                      className='form-control mt-2 inputReviews'
                      placeholder='Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor='Email' className='mt-3 fw-bold'> {t("email")} :</label>
                    <input
                      name='Email'
                      id='Email'
                      className='form-control mt-1 inputReviews mt-2'
                      placeholder='E-mail'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className='pb-4'>
                    <label htmlFor='Note' className='mt-3 fw-bold'>{t("note")}:</label>
                    <textarea
                      id='Note'
                      className='form-control mt-2'
                      placeholder='Note'
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      required
                    />
                  </div>
                  <div className='rating d-flex'>
                    <label className='mt-3 fw-bold'>{t("rating")}:</label>
                    <div className='stars starcolor mt-1 mx-5'>
                      {[1, 2, 3, 4, 5].map((star) => (
                        <Star key={star} star={star} />
                      ))}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-color text-white mb-4 mt-3"
                    disabled={loading} // Disable button while loading
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                      'Send'
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 d-none d-lg-block'>
            <img src='https://www.globitalmarketing.com/sg/wp-content/uploads/2021/07/df453we.png' className='w-100 reviweimg' alt="Review illustration" />
          </div>
        </div>
      </div>

      <ShowReviews />
    </>
  );
}
