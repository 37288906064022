import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context/Context';
import { useTranslation } from 'react-i18next';

export default function Profile() {
    const { url, lan } = useContext(UserContext);
    const [profileData, setProfileData] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem("token");

        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/user-profile?lang=${lan}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setProfileData(response?.data?.data || []);
            } catch (error) {
                console.error("Error fetching user profile data", error);
            }
        };

        fetchData();
    }, [url, lan]);


    return (
        <div className='container'>
            <div className='row'>
                <div className='col-6 mx-auto boxshado'>
                    {profileData && (
                    <div className='mx-3 '>
                    <div className="col-12 mt-3">
                            {/* <h3>user_rofile</h3> */}
                            <div className='d-flex justify-content-between'>
                                <p className='text-color'>  {t("first_name")}  </p>
                                <p className='fw-bold mx-2 text-color'>{profileData?.first_name}</p>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <p className='text-color'> {t("last_name")}  </p>
                                <p className='fw-bold mx-2 text-color'>{profileData?.last_name}</p>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <p className='text-color'> {t("email")} </p>
                                <p className='fw-bold mx-2 text-color'>{profileData?.email}</p>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <p className='text-color'> {t("phone")} </p>
                                <p className='fw-bold mx-2 text-color'>{profileData?.phone}</p>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <p className='text-color'> {t("country")}</p>
                                <p className='fw-bold mx-2 text-color'>{profileData?.country}</p>
                            </div>

                        </div>
                    </div>
                    )}
                </div>

            </div>
        </div>
    );
}
