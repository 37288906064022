import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { UserContext } from '../Context/Context';
import axios from 'axios';

export default function Testmonials() {
    const { t } = useTranslation();
    const languag = localStorage.getItem("language");
    const [reviews, setReviews] = useState([]); // Renamed 'Date' to 'reviews' for clarity
    const [loading, setLoading] = useState(true);

    const { url, lan } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/reviews?lang=${lan}`);
                setReviews(response?.data?.data || []); // Store reviews data
            } catch (error) {
                console.error("Error fetching slider data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [lan, url]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='mt-5'>
            <div className='text-center mb-4'>
                <h5 className='text-color'>{t('Our Clients Reviews')}</h5>
            </div>

            <div className='container'>
                <div className='row'>
                    {loading ? (
                        <div className="text-center">Loading...</div> 
                    ) : (
                        <Slider {...settings}>
                            {reviews.map((review, index) => (
                                <div key={index} className='mx-2'>
                                    <div className='sliderboxshado p-4'>
                                    <div>
                                                        <h6 className='fw-bold text-color'>{review.name}</h6> {/* Display the name */}
                                                    </div>
                                      
                                         
                                        <div className='mt-2'>
                                            <div className='d-flex align-items-center'>
                                      
                                                <div className='mt-2'>
                                                <div className='text-white'>{review.comment}</div>
                                                   
                                                    <div>
                                                        {[...Array(review.rate)].map((_, starIndex) => (
                                                            <i key={starIndex} className="fa-solid fa-star text-yellow fa-1x"></i>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
            </div>
        </div>
    );
}
