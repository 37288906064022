import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context/Context';
import axios from 'axios';

export default function CounterServices() {
  const { url, lan } = useContext(UserContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/home?lang=${lan}`);
        setData(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching slider data", error);
      }
    };

    fetchData();
  }, [lan, url]);

  console.log(data.setting?.result_image);

  return (
    <div className='bg-blue'>
      <div className='container pt-3'>
        <div className='row'>
          {data?.result?.map((item, index) => (
            <React.Fragment key={index}>
              <div className='col-lg-2 col-md-6 col-sm-12 mt-3'>
                <div className='text-color text-center'>
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </div>
              </div>
              {/* Add the image div after the first two items */}
              {(index === 1) && (
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='text-center mb-3'>
                    <img src={data.setting?.result_image} alt="imgResult" className='imgResult' />
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
















{/* 
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <div className='text-color text-center'>
                            <h1>+10</h1>
                            <p>Professional Stuff</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='text-center mb-3'>
                            <img src={data.setting?.result_image} />
                        </div>
                    </div>
                    
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <div className='text-color text-center'>
                            <h1>%100</h1>
                            <p>Happy Clients</p>
                        </div>
                    </div>
                    
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <div className='text-color text-center'>
                            <h1>+5</h1>
                            <p>Years Of Experience</p>
                        </div>
                    </div> */}