import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import { Offline, Online } from 'react-detect-offline';
import offlineimg from "../../Img/offline.jpg";
import MinNavbar from '../Navbar/MinNavbar';

export default function Layout() {
  const location = useLocation();

  return (
    <>
      <Navbar />
      
      {/* Conditionally render MinNavbar, hide it on the Home page */}
      {location.pathname !== '/' && <MinNavbar />}
      
      <Outlet />

      <Footer />
      <>
        {/* <Offline> Offline the App    
        <div className='netWork'>
          <img src={offlineimg} alt="Offline" />
        </div>
        </Offline> */}
      </>
    </>
  );
}
