import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import arTranslation from './locales/ar/translation.json';
import deTranslation from './locales/de/translation.json';  // إضافة الترجمة الألمانية

const savedLanguage = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      ar: {
        translation: arTranslation,
      },
      de: {
        translation: deTranslation,  // إضافة اللغة الألمانية
      }
    },
    lng: savedLanguage,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    }
  });

const updateBodyDirection = (language) => {
  const direction = language === 'ar' ? 'rtl' : 'ltr';
  document.body.setAttribute('dir', direction);
};

updateBodyDirection(savedLanguage);

i18n.on('languageChanged', (lng) => {
  updateBodyDirection(lng);
  localStorage.setItem('language', lng);
});

export default i18n;
