import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../Context/Context';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function OurServices() {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [date, setDate] = useState([]);
    const [loading, setLoading] = useState(true);

    const { url, lan } = useContext(UserContext);


    
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/home?lang=${lan}`);
        setData(response?.data?.data?.setting || []);
      } catch (error) {
        console.error("Error fetching slider data", error);
      }
    };

    fetchData();
  }, [lan, url]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/services?lang=${lan}&num=8`);
                // setData(response?.data?.data?.services || []);
                setDate(response?.data?.data || [])
            } catch (error) {
                console.error("Error fetching slider data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [lan, url]);
    // console.log( "2020", data?.service_image);

    // console.log(date?.setting.service_image);
    // console.log(data[1]);

    return <>
        <div className='bgsection' id='Services'>
            <div className=''>
                <div className='text-center  '>
                    <p className=' text-color fw-bold pt-3'>  {t("services")}   </p>
                    <h2 className='text-color p-0 m-0'>  {t("smileguaranteed")}   </h2>
                </div>
                <div className='container mt-5'>
    <div className='row'>
        {/* العمود الأيسر */}
        <div className='col-lg-4'>
            {date.slice(0, 4).map((data, index) => (
                <div key={index} className='col-12 mb-4'>
                    <Link to={`/DetalseServices/${data?.id}`}>
                        <div className='serv service-right'>
                            <div className='serv-title text-dark w-70'>
                                <h4 className='text-center text-color'>{data?.name}</h4>
                                <p className='text-center text-color'>
                                    {data?.description?.split(' ').slice(0, 3).join(' ')}
                                </p>
                            </div>
                            <div className='servimg d-center w-25'>
                                <img src={data?.image} className='d-center w-100 p-2' alt="Service" />
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>

        {/* العمود الأوسط لعرض الصورة الكبيرة */}
        <div className='col-lg-4 col-md-12 mt-0 pt-0'>
            <div className='service-big-img'>
                <img src={data?.service_image} alt="Big Service" />
            </div>
        </div>

        {/* العمود الأيمن */}
        <div className='col-lg-4 mt-4'>
            {date.slice(4, 8).map((data, index) => (
                <div key={index} className='col-12 mb-4'>
                    <Link to={`/DetalseServices/${data?.id}`}>
                        <div className='serv service-right'>
                            <div className='serv-title text-dark w-70'>
                                <h4 className='text-center text-color'>{data?.name}</h4>
                                <p className='text-center text-color'>
                                    {data?.description?.split(' ').slice(0, 3).join(' ')}
                                </p>
                            </div>
                            <div className='servimg d-center w-25'>
                                <img src={data?.image} className='d-center w-100 p-2' alt="Service" />
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    </div>
</div>



            </div>
        </div>

    </>
}






{/* <Link to={`/DetalseServices/${data[1]?.id}`}>
                                <div className='serv service-right mt-5'>
                                    <div className='serv-title text-dark w-70 '>
                                        <h4 className='text-center text-color'>  {data[1]?.name}  </h4>
                                        <p className='text-center text-color'>
                                            {data[1]?.description?.split(' ').slice(0, 3).join(' ')}
                                        </p>
                                    </div>
                                    <div className='servimg d-center w-25 '>
                                        <img src={data[1]?.image} className='d-center w-100 p-2' />



                       
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/DetalseServices/${data[3]?.id}`}>
                                <div className='serv service-right mt-5'>
                                    <div className='serv-title text-dark w-70 '>
                                        <h4 className='text-center text-color'>  {data[3]?.name}  </h4>
                                        <p className='text-center text-color'>
                                            {data[3]?.description?.split(' ').slice(0, 3).join(' ')}
                                        </p>
                                    </div>
                                    <div className='servimg d-center w-25 '>
                                        <img src={data[3]?.image} className='d-center w-100 p-2' />
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/DetalseServices/${data[2]?.id}`}>
                            <div className='serv service-right mt-5'>
                                <div className='serv-title text-dark w-70 '>
                                    <h4 className='text-center text-color'>  {data[2]?.name}  </h4>
                                    <p className='text-center text-color'>
                                        {data[2]?.description?.split(' ').slice(0, 3).join(' ')}
                                    </p>
                                </div>
                                <div className='servimg d-center w-25 '>
                                    <img src={data[2]?.image} className='d-center w-100 p-2' />



                                </div>
                            </div>

                            </Link> */}
{/* </div> */ }
{/* <div className='col-lg-4 col-md-12'>
                            <div className='service-big-img'>
                                <img src={date?.setting?.service_image} />
                            </div>
                        </div>                     */}
{/* <div className='col-lg-4 col-md-12'>

                        <Link to={`/DetalseServices/${data[5]?.id}`}>
                        <div className='serv service-right mt-5'>
                                <div className='serv-title text-dark w-70 '>
                                    <h4 className='text-center text-color'>  {data[5]?.name}  </h4>
                                    <p className='text-center text-color'>
                                        {data[5]?.description?.split(' ').slice(0, 3).join(' ')}
                                    </p>
                                </div>
                                <div className='servimg d-center w-25 '>
                                    <img src={data[5]?.image} className='d-center w-100 p-2' />
                                </div>
                            </div>
                            </Link>

                            <Link to={`/DetalseServices/${data[4]?.id}`}>
                            <div className='serv service-right mt-5'>
                                <div className='serv-title text-dark w-70 '>
                                    <h4 className='text-center text-color'>  {data[4]?.name}  </h4>
                                    <p className='text-center text-color'>
                                        {data[4]?.description?.split(' ').slice(0, 3).join(' ')}
                                    </p>
                                </div>
                                <div className='servimg d-center w-25 '>
                                    <img src={data[4]?.image} className='d-center w-100 p-2' />



                                </div>
                            </div>   
                            </Link>

                            <Link to={`/DetalseServices/${data[6]?.id}`}>
                             <div className='serv service-right mt-5'>
                                <div className='serv-title text-dark w-70 '>
                                    <h4 className='text-center text-color'>  {data[6]?.name}  </h4>
                                    <p className='text-center text-color'>
                                        {data[6]?.description?.split(' ').slice(0, 3).join(' ')}
                                    </p>
                                </div>
                                <div className='servimg d-center w-25 '>
                                    <img src={data[6]?.image} className='d-center w-100 p-2' />



                                 
                                </div>
                            </div>
                            </Link>
                         
                            <Link to={`/DetalseServices/${data[7]?.id}`}>
                            <div className='serv service-right mt-5'>
                                <div className='serv-title text-dark w-70 '>
                                    <h4 className='text-center text-color'>  {data[7]?.name}  </h4>
                                    <p className='text-center text-color'>
                                        {data[7]?.description?.split(' ').slice(0, 3).join(' ')}
                                    </p>
                                </div>
                                <div className='servimg d-center w-25 '>
                                    <img src={data[7]?.image} className='d-center w-100 p-2' />



                                
                                </div>
                            </div>
                            </Link>
                          

                        </div> */}






// [Your Name - Group Name and time with online or offline - Assignment Name and Number]

// Ahmed_Walid_C43_Sun&Wed_7PM_Online_Assignment_1_01126303082