import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { BallTriangle } from 'react-loader-spinner';

export default function Info() {
  const { t } = useTranslation();
  const { url, lan } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch slider data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/about?lang=${lan}`);
        setData(response?.data?.data || []);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lan, url]);

  if (loading) {
    return <p>
            <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0d2338"
                ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
    </p>; // Show loading indicator while fetching data
  }
  console.log(data?.aboutHeader);

  // 11 ===> 6
  return (
    <>
      <Helmet>
        <title> {t("aboutUs")} </title>
      </Helmet>
      <div
        className='infoabout'
        style={{
          backgroundImage: `url('${data?.aboutHeader?.file}')`, // Dynamically set the image from API data
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '50vh',
          display: 'flex'
        }}>
        <div className='d-center mx-auto'>
          <h1 className='text-color'>      {data?.aboutHeader?.title}     </h1> {/* Translated header */}
        </div>
      </div>
      <div className='mt-4 container'>
        <div className='row'>
          {data?.about?.map((img, index) => (
            <React.Fragment key={index}>
              <div className='col-lg-6 col-md-12 mt-3'>
                <p className='text-color'>{img?.description}</p>
              </div>
              <div className='col-lg-6 col-md-12 mt-3'>
                <div>
                  <img className='w-100' src={img?.file} alt="About us" />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
}
