import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { t } from 'i18next';

export default function Footer() {


  const { url, lan, setLan } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const { pathname } = useLocation();

  // Fetch slider data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/home?lang=${lan}`);
        setData(response?.data?.data?.setting || []);
      } catch (error) {
        console.error("Error fetching slider data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lan, url]);
  console.log(data?.location);

  return <>
    <footer className="bg-color text-light mt-5 pb-3">
      <div className="container">
        <div className="row text-center">
          {/* <div className="col-md-3 mt-5 text-color">
            <i className="fas fa-phone fa-1x text-color mb-2"></i>
            <h5>Emergency Email</h5>
            <p>+201551414900</p>
            <p>Call Us any time 24/7</p>
          </div> */}
          <div className="col-md-4 mt-5 text-color">
            <i className="fas fa-map-marker-alt font-27 text-color mb-2"></i>
            <h5> {t("location")} </h5>
            <div className='imgfooter'>
              <a href={data?.location} target='_blank'>

                <img className='w-50' src='https://images.hindustantimes.com/tech/img/2022/05/29/960x540/Untitled_design_-_2022-05-29T154125.807_1653819139818_1653819154029.jpg' />
              </a>
            </div>
          </div>
          <div className="col-md-4 mt-5 text-color">
            {/* <i class="fa-solid fa-envelope font-27"></i> */}
            <h5> {t("contactus")} </h5>
            <p>{data?.email} </p>
            <div className='d-flex d-center'>
              <p> {data?.phone} </p>
            </div>
          </div>
          <div className="col-md-4 mt-5 text-color">
            {/* <i className="fas fa-clock font-27 text-color mb-2"></i> */}
            <h5 >  {t("linkfooter")}  </h5>

            <div className=''>
              <a className="  fw-bold text-color d-block mt-2" href="/">{t("home")}</a>
              <a className="  fw-bold text-color d-block mt-2" href="/AllServices">{t("services")}</a>
              <a className="  fw-bold text-color d-block mt-2" href="/faq">{t("faq")}</a>
              <a className="  fw-bold text-color d-block mt-2" href="/Reviews">{t("reviews")}</a>

            </div>

          </div>
        </div>
      </div>

    </footer>
    <div className="text-center  bg-color boxshado ">
      <p className='p-2 m-0 text-color'>All Rights Are Reserved© EA DENTAL AESTHETICS 2024</p>
    </div>

  </>
}
