import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { UserContext } from '../Context/Context';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';

export default function Detailseadentalclinic() {
    let { id } = useParams();
    console.log(id);

    const [Date, setDate] = useState({});
    const [loading, setLoading] = useState(true);

    const { url, lan } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/doctors/${id}?lang=${lan}`);
                setDate(response?.data?.data || {});
            } catch (error) {
                console.error("Error fetching doctors data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [lan, url, id]);

    if (loading) return <div className='d-center'>
        <BallTriangle
    height={100}
    width={100}
    radius={5}
    color="#0d2338"
    ariaLabel="ball-triangle-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    />
    </div>

    return (
        <>
            <Helmet>
                <title>Dr. {Date?.name || 'Loading...'} </title>
            </Helmet>

            <div className='container-fluid'>
                <div>
                    {/* Check if images array exists and has at least one image */}
                    {Date?.images?.[0] ? (
                        <img src={Date.images[0].image} className='w-100 imgabout' alt="Doctor" />
                    ) : (
                        <p>No image available</p>
                    )}
                </div>

                <div className='container mt-4'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-12'>
                            <div className='text-start mx-4'>
                                <h3 className='text-color'>Dr. {Date?.name} </h3>

                                <div className='mt-3'>
                                    <p className='text-white'>{Date?.description}</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-lg-4 col-md-12'>
                            <div className='boxshado'>
                                <div className='p-4'>
                                    {/* Check if there is a second image */}
                                    {Date?.images?.[1] ? (
                                        <img src={Date.images[1].image} className='w-100' alt="Doctor" />
                                    ) : (
                                        <p>No second image available</p>
                                    )}
                                </div>

                                <div className='mt-3 text-center px-4 pb-3'>
                                    <h3 className='text-color'>Dr. {Date?.name} </h3>
                                    <p className='text-white'>{Date?.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
