import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../Context/Context';
import axios from 'axios';

export default function ImgServices() {


    const [data, setData] = useState([]);
    const [date, setDate] = useState([]);
    const [loading, setLoading] = useState(true);

    const { url, lan } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/home?lang=${lan}`);
                setData(response?.data?.data?.galleries || []);
                setDate(response?.data?.data)
            } catch (error) {
                console.error("Error fetching slider data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [lan, url]);


  return <>
    <div className='bgsection ' id='imgservise'>
        <div className=''>
            <div className='container overflow-auto '>
                <div className='row mt-5 '>
                {data.map((img)=>[
                    <>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                        <div>
                            <img src={img?.image} className='img-fluid'/>
                        </div>
                    </div>
                    </>
                ])}
                </div>
            </div>
        </div>
    </div>
  </>
}
