import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context/Context';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function AllServices() {

  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(true);

  const { url, lan } = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/home?lang=${lan}`);
        setData(response?.data?.data?.services || []);
        setDate(response?.data?.data);
      } catch (error) {
        console.error("Error fetching services data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lan, url ]);

  return (
    <>
      <Helmet>
        <title> {t("allServices")} </title>
      </Helmet>
      <div className='container'>
        <div className='row mt-5'>
          {data?.map((card) => (
            <div className='col-lg-4 col-md-6 col-sm-12 mt-3' key={card?.id}>
              <Link to={`/DetalseServices/${card?.id}`}>
                <div className='flex-column boxshado'>
                  <div className='imgcard d-center p-2'>
                    <img className='w-100' src={card?.image} alt={card?.name} />
                  </div>
                  <div>
                    <div className='justify-content-between align-content-between w-100 px-3'>
                      <div className='d-flex justify-content-between align-content-between '>
                        <p className='text-white'> {t("name")} </p>
                        <p className='text-color fw-bold mx-2'>{card?.name}</p>
                      </div>
                      <div className='d-flex justify-content-between align-content-between '>
                        <p className='text-white'> {t("price")}</p>
                        <p className='text-color fw-bold mx-2'>{card?.price}</p>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-content-between mx-3'>
                      <p className='text-white'> {t("description")}</p>
                      <p className='text-color fw-bold mx-2'>
                        {card?.description?.split(' ').slice(0, 8).join(' ')}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
